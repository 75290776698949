import React, { useState, useEffect, useMemo } from "react";
import { io } from "socket.io-client";
import { config } from "../config";
import { getToken } from "../api/axiosClient";

const SocketContext = React.createContext();

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(undefined);
  const [connection, setConnection] = useState({
    isConnected: false,
    open: false,
    severity: 'success',
    title: '',
    message: '',
  });

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    const conn = io(config.api, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 2000,

    });

    let timer = null;
    conn.on("connect", async () => {
      setSocket(conn);

      clearTimeout(timer);
      setConnection({
        isConnected: true,
        open: true,
        severity: 'success',
        title: 'Server Connected',
        message: "You're ready to see real time updates"
      });

      const token = await getToken();
      if (token) {
        conn.emit("group_join", { token });
      }
    });

    conn.on("connect_error", (err) => {
      timer = setTimeout(() => {
        setConnection({
          isConnected: false,
          open: true,
          severity: 'error',
          title: 'Server Disconnected',
          message: "Server disconnected due connection error, updates will not appear!",
        });
      }, 2000);
    });

    conn.on("disconnect", (reason) => {
      setConnection({
        isConnected: false,
        open: true,
        severity: 'error',
        title: 'Server Disconnected',
        message: `You're disconnected due ${reason}. Uptes will not appear!`,
      });
    })
  };
  const values = useMemo(
    () => ({ socket, connection, onDialogClose: () => setConnection({ ...connection, open: false }) }),
    [socket, connection]
  );

  return (
    <SocketContext.Provider value={values}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider };
