import React, { useState, useEffect } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
const SocketStatus = ({ isConnected }) => {
    return (
        <Box sx={{ alignContent: 'center' }}>
            <Tooltip title={isConnected ? `You're connected and ready to watch load changes` : `You're disconnected and unable to see load changes`}>
                <Box
                    sx={{
                        width: '1rem',
                        height: '1rem',
                        borderRadius: '50%',
                        background: isConnected
                            ? 'linear-gradient(135deg, #4caf50, #66bb6a)'
                            : '#f44336',
                        animation: isConnected ? 'pulse 1.5s infinite ease-in-out' : 'none',
                        transition: 'background 0.5s ease',
                    }}
                >
                    {/* CSS animation for pulsing */}
                    <style>
                        {`
                    @keyframes pulse {
                    0% {
                        transform: scale(1);
                        opacity: 1;
                    }
                    50% {
                        transform: scale(1.1);
                        opacity: 0.7;
                    }
                    100% {
                        transform: scale(1);
                        opacity: 1;
                    }
                    }
                `}
                    </style>
                </Box>
            </Tooltip>
        </Box>
    );
};

export default SocketStatus;
