import {
  IconButton,
  Box,
  TextField,
  Input,
  FormControl,
  Autocomplete,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment,
  Tab,
  Tabs,
  List,
  ListItem,
  Card,
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  CardContent,
} from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import Moment from 'moment';
import loadApi from '../../api/loadApi';
import '../../css/custom-editor.css';
import companyApi from '../../api/companyApi';
import Loading from './Loading';
import { useSelector } from 'react-redux';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTime } from 'luxon';
import { SiteContext } from '../../contexts/site.context';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { LoadMode } from '../../constants/boardTypes';
import { FormPODAndBOD } from '../generic/FormPODAndBOD';

const locationFieldStyle = { marginTop: 1 };

const companyOrder = ['customer', 'shipper', 'carrier', 'receiver'];

class Load {
  _id = undefined;
  title = '';
  section = '';
  alert = '';
  notes = '';
  customer = null;
  shipper = null;
  carrier = null;
  receiver = null;
  pickup_address = '';
  delivery_address = '';
  pro_number = '';
  tracking_url = '';
  tracking_number = '';
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const LoadModal = (props) => {
  const boardId = props.boardId;
  const user = useSelector((state) => state.user.value);
  const siteContext = useContext(SiteContext);  
  const [files, setFiles] = useState({pod: [], bod: []});
  const [load, setLoad] = useState(new Load());
  const [section, setSection] = useState({
    _id: null,
    customer_access: null,
    allow_archive: false,
    title: null,
  });
  const [alert, setAlert] = useState({ number: '', unit: '' });
  const [companies, setCompanies] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newCompanyTypes, setNewCompanyTypes] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const [open, setOpen] = React.useState(false);
  const [disableSaveCloseBtn, setDisableSaveCloseBtn] = useState(false);

  useEffect(() => {
    if (props.load !== undefined && load._id === undefined) {
      setSection(
        props.sections.find((section) => section.id === props.load.section),
      );
      setLoad(
        Object.assign(new Load(), {
          ...props.load,
          ...{ export_to_qe: false },
        }),
      );
      setAlert({ number: '', unit: '' });
      lockLoad();
      if (companies.length === 0) getCompanies();
    } else setLoad(new Load());
  }, [props.load]);

  useEffect(()=>{
    const hasEmptyETA = load?.locations?.some(location => location?.estimated_arrival_time === null);
    const isPending = section.title === 'Pending' || section.title === 'Waiting' || section.title === 'Dummy'; 
    setDisableSaveCloseBtn(!isPending && hasEmptyETA);
  },[load, section]);

  const lockLoad = async () => {
    setLoading(true);
    try {
      await loadApi.lock(boardId, props.load.id, {
        user_locked: {
          user_id: user._id,
          name: user.firstname,
          timestamp: new Date(),
        },
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      let msg = 'Failed to Lock!';
      if (err.data.error) msg = err.data.error;
      props.onClose(msg);
    }
  };

  const unlockLoad = async () => {
    await loadApi.lock(boardId, props.load.id, {
      user_locked: null,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({
      open: false,
      severity: 'success',
      message: '',
    });
  };

  const getCompanies = async () => {
    try {
      const res = await companyApi.getAll();
      setCompanies(res);
    } catch (err) {
      setSnackbar({
        open: true,
        severity: 'error',
        message: 'Failed to get companies',
      });
    }
  };

  const onClose = async (saved) => {
    unlockLoad();
    if (Object.values(newCompanyTypes).find((type) => type === true)) {
      getCompanies();
    }
    setNewCompanyTypes({});
    props.onClose(null, saved);
  };

  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const canDelete = () => {
    return !user.isCustomer || section.customer_access === 'delete';
  };

  const isReadOnly = () => {
    return user.isCustomer && section.customer_access === 'read';
  };

  const deleteLoad = async () => {
    if (window.confirm('Are you sure you want to delete load?')) {
      try {
        await loadApi.delete(boardId, load.id);
        props.onDelete(load);
      } catch (err) {
        setSnackbar({
          open: true,
          severity: 'error',
          message: 'Failed to delete load',
        });
      }
    }
  };

  const updateTitle = async (event) => {
    const loadCopy = { ...load };
    loadCopy.qe_id = event.target.value;
    setLoad(loadCopy);
  };

  const updateProNumber = (event) => {
    const loadCopy = { ...load };
    loadCopy.pro_number = event.target.value;
    setLoad(loadCopy);
  };

  const updateReferenceId = (event) => {
    const loadCopy = { ...load };
    loadCopy.reference_id = event.target.value;
    setLoad(loadCopy);
  };

  const updatePrice = (event) => {
    const loadCopy = { ...load };
    loadCopy.price = event.target.value;
    setLoad(loadCopy);
  };

  const updateCompany = async (id, type) => {
    const loadCopy = { ...load };
    loadCopy[type] = id;
    setLoad(loadCopy);
  };

  const updateTrackingUrl = async (event) => {
    const loadCopy = { ...load };
    loadCopy.tracking_url = event.target.value;
    setLoad(loadCopy);
  };

  const updateShippingAddress = (event) => {
    const loadCopy = { ...load };
    loadCopy.pickup_address = event.target.value;
    setLoad(loadCopy);
  };

  const updateDestinationAddress = (event) => {
    const loadCopy = { ...load };
    loadCopy.delivery_address = event.target.value;
    setLoad(loadCopy);
  };

  const updateAlert = (alertCopy) => {
    setAlert(alertCopy);
    let alertTime = Moment();
    alertTime = alertTime.add(alertCopy.number, alertCopy.unit);
    load.alert = alertTime;
    setLoad(load);
  };

  const updateNotes = (event) => {
    const loadCopy = { ...load };
    loadCopy.notes = event.target.value;
    setLoad(loadCopy);
  };

  const updateStatus = (event) => {
    const newStatus = event.target.value;
    const loadCopy = { ...load };
    loadCopy.old_section = load.section;
    loadCopy.section = newStatus;
    setLoad(loadCopy);
  };

  const save = async () => {
    try {
      await loadApi.update(boardId, load.id, load);
      onClose(true);
    } catch (err) {
      let msg = 'Failed to Save!';
      if (err.data.error) msg = err.data.error;
      setSnackbar({
        open: true,
        severity: 'error',
        message: msg,
      });
    }
  };

  const archive = async () => {
    try {
      load.archived = true;
      await loadApi.update(boardId, load.id, load);
      onClose(true);
    } catch (err) {
      let msg = 'Failed to Archive!';
      if (err.data.error) msg = err.data.error;
      setSnackbar({
        open: true,
        severity: 'error',
        message: msg,
      });
    }
  };

  const typeUpper = (type) => {
    return type.charAt(0).toUpperCase() + type.slice(1);
  };

  const renderItems = () => {
    return (
      <TableContainer component={Box} my={2} p={2} sx={{ overflow: 'auto' }}>
        <Box sx={{ display: 'flex', marginBottom: 2, alignContent: 'center' }}>
          <Typography variant="h6" id="tableTitle" component="div">
            Items
          </Typography>
          {!isReadOnly() && (
            <Button
              sx={{ marginLeft: 2 }}
              variant="outlined"
              size="small"
              startIcon={<AddOutlined />}
              onClick={() => {
                const loadCopy = { ...load };
                loadCopy.items.push({
                  reference_id: '',
                  weight: null,
                  length: null,
                  height: null,
                  width: null,
                });
                setLoad(loadCopy);
              }}
            >
              ADD
            </Button>
          )}
        </Box>
        <Table sx={{ minWidth: 650 }} aria-label="shipping table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: { xs: 1 } }}>Reference ID</TableCell>
              <TableCell sx={{ padding: { xs: 1 } }}>Weight</TableCell>
              <TableCell sx={{ padding: { xs: 1 } }}>Length</TableCell>
              <TableCell sx={{ padding: { xs: 1 } }}>Height</TableCell>
              <TableCell sx={{ padding: { xs: 1 } }}>Width</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {load.items &&
              load.items.map((row, index) => (
                <Fragment key={'item_' + index}>
                  <>
                    {!row._id && (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            padding: { xs: 1 },
                          }}
                        >
                          <TextField
                            size="small"
                            label={'Reference ID'}
                            value={row.reference_id}
                            fullWidth
                            sx={locationFieldStyle}
                            onChange={(e) => {
                              load.items[index].reference_id = e.target.value;
                              setLoad({ ...load });
                            }}
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            padding: { xs: 1 },
                          }}
                        >
                          <TextField
                            size="small"
                            label={'Weight'}
                            type="number"
                            value={row.eight}
                            fullWidth
                            sx={locationFieldStyle}
                            onChange={(e) => {
                              load.items[index].weight = e.target.value;
                              setLoad({ ...load });
                            }}
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            padding: { xs: 1 },
                          }}
                        >
                          <TextField
                            size="small"
                            label={'Length'}
                            value={row.length}
                            fullWidth
                            sx={locationFieldStyle}
                            onChange={(e) => {
                              load.items[index].length = e.target.value;
                              setLoad({ ...load });
                            }}
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            padding: { xs: 1 },
                          }}
                        >
                          <TextField
                            size="small"
                            label={'Height'}
                            value={row.height}
                            fullWidth
                            sx={locationFieldStyle}
                            onChange={(e) => {
                              load.items[index].height = e.target.value;
                              setLoad({ ...load });
                            }}
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            padding: { xs: 1 },
                          }}
                        >
                          <TextField
                            size="small"
                            label={'Width'}
                            value={row.width}
                            fullWidth
                            sx={locationFieldStyle}
                            onChange={(e) => {
                              load.items[index].width = e.target.value;
                              setLoad({ ...load });
                            }}
                          />
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            padding: { xs: 1 },
                          }}
                        >
                          <Button
                            size="small"
                            color="error"
                            variant="outlined"
                            sx={{ marginTop: 1 }}
                            onClick={() => {
                              load.items.splice(index, 1);
                              setLoad({ ...load });
                            }}
                          >
                            DELETE
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                    {row._id && (
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            width: '20%',
                            padding: 1,
                          }}
                        >
                          {row.reference_id}
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            width: '20%',
                            padding: 1,
                          }}
                        >
                          {row.weight}
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            width: '20%',
                            padding: 1,
                          }}
                        >
                          {row.length}
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            width: '20%',
                            padding: 1,
                          }}
                        >
                          {row.height}
                        </TableCell>
                        <TableCell
                          scope="row"
                          sx={{
                            verticalAlign: 'top',
                            width: '20%',
                            padding: 1,
                            position: 'relative',
                          }}
                        >
                          {row.width}
                          <Box
                            sx={{
                              float: 'right',
                              position: 'absolute',
                              top: '3px',
                              right: 0,
                              marginRight: 2,
                              marginBottom: 2,
                            }}
                          >
                            <Button
                              size="small"
                              color="error"
                              variant="outlined"
                              onClick={() => {
                                load.items.splice(index, 1);
                                setLoad({ ...load });
                              }}
                            >
                              DELETE
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              sx={{ marginLeft: 1 }}
                              onClick={() => {
                                load.items[index]._id = undefined;
                                setLoad({ ...load });
                              }}
                            >
                              EDIT
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderLocations = (type, title) => {

    return (
      <TableContainer component={Box} my={2} p={2} sx={{ overflow: 'auto' }}>
        <Box sx={{ display: 'flex', marginBottom: 2, alignContent: 'center' }}>
          <Typography variant="h6" id="tableTitle" component="div">
            {title}{' '}
          </Typography>
          {!isReadOnly() && (
            <Button
              sx={{ marginLeft: 2 }}
              variant="outlined"
              size="small"
              startIcon={<AddLocationAltOutlinedIcon />}
              onClick={() => {
                const loadCopy = { ...load };
                loadCopy.locations.push({
                  type: type,
                  address: {},
                  contact: {},
                  arrival_time: null,
                  estimated_arrival_time: null,
                  departure_time: null,
                });
                setLoad(loadCopy);
              }}
            >
              ADD
            </Button>
          )}
        </Box>
        {load.locations &&
          load.locations.map((row, index) => (
            <Fragment key={'location_' + type + index}>
              {row.type === type && (
                <Card sx={{ marginBottom: 0.5 }}>
                  <CardContent>
                    <Grid container>
                      <Grid item sm={12} md={4} sx={{ paddingRight: 1 }}>
                        <b>Address:</b>
                        <br />
                        {row.address._id && (
                          <>
                            {row.address.line1}
                            <br />
                            {row.address.line2 && (
                              <>
                                {row.address.line2}
                                <br />
                              </>
                            )}
                            {row.address.city}, {row.address.state}{' '}
                            {row.address.zip}
                            <br />
                            {row.address.country}
                          </>
                        )}
                        {!row.address._id && (
                          <>
                            <TextField
                              size="small"
                              label={'Line 1'}
                              value={row.address.line1}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.line1 =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'Line 2'}
                              value={row.address.line2}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.line2 =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'City'}
                              value={row.address.city}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.city =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'State'}
                              value={row.address.state}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.state =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'Zip'}
                              value={row.address.zip}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.zip =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'Country'}
                              value={row.address.country}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.country =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item sm={12} md={4} sx={{ paddingRight: 2 }}>
                        <b>Contact:</b>
                        <br />
                        {row.address._id &&
                          row.contact &&
                          (row.contact.name ||
                            row.contact.email ||
                            row.contact.phone) && (
                            <>
                              {row.contact.name}
                              <br />
                              Email: {row.contact.email}
                              <br />
                              Phone: {row.contact.phone}
                            </>
                          )}
                        {!row.address._id && (
                          <>
                            <TextField
                              size="small"
                              label={'Name'}
                              value={row.contact.name}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].contact.name =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'Email'}
                              value={row.contact.email}
                              fullWidth
                              type="email"
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].contact.email =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'Phone'}
                              value={row.contact.phone}
                              fullWidth
                              type="tel"
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].contact.phone =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item md={12} lg={4}>
                        <Table className="times-table">
                          <TableRow>
                            <TableCell>
                              <b>Req From Time</b>:
                            </TableCell>
                            <TableCell>
                              {row.address._id && row.request_from_time
                                ? new Date(
                                  row.request_from_time,
                                ).toLocaleString()
                                : ''}
                              {!row.address._id && (
                                <DateTimePicker
                                  id={'datepicker_at_' + type + index}
                                  value={DateTime.fromISO(
                                    row.request_from_time,
                                  )}
                                  onChange={(newValue) => {
                                    const loadCopy = { ...load };
                                    console.log(
                                      index,
                                      load.locations.filter(
                                        (loc) => loc.type === type,
                                      ),
                                    );
                                    load.locations[index].request_from_time =
                                      newValue;
                                    setLoad(loadCopy);
                                  }}
                                  slotProps={{ textField: { size: 'small' } }}
                                  fullWidth
                                  sx={locationFieldStyle}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>Req To Time</b>:
                            </TableCell>
                            <TableCell>
                              {row.address._id && row.request_to_time
                                ? new Date(row.request_to_time).toLocaleString()
                                : ''}
                              {!row.address._id && (
                                <DateTimePicker
                                  id={'datepicker_eat_' + type + index}
                                  value={DateTime.fromISO(row.request_to_time)}
                                  onChange={(newValue) => {
                                    const loadCopy = { ...load };
                                    load.locations[index].request_to_time =
                                      newValue;
                                    setLoad(loadCopy);
                                  }}
                                  slotProps={{ textField: { size: 'small' } }}
                                  fullWidth
                                  sx={locationFieldStyle}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>Arrival Time</b>:{' '}
                            </TableCell>
                            <TableCell>
                              {row.address._id && row.arrival_time
                                ? new Date(row.arrival_time).toLocaleString()
                                : ''}
                              {!row.address._id && (
                                <DateTimePicker
                                  id={'datepicker_at_' + type + index}
                                  value={DateTime.fromISO(row.arrival_time)}
                                  onChange={(newValue) => {
                                    const loadCopy = { ...load };
                                    console.log(
                                      index,
                                      load.locations.filter(
                                        (loc) => loc.type === type,
                                      ),
                                    );
                                    load.locations[index].arrival_time =
                                      newValue;
                                    setLoad(loadCopy);
                                  }}
                                  slotProps={{ textField: { size: 'small' } }}
                                  fullWidth
                                  sx={locationFieldStyle}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>Est Arrival Time</b>:
                            </TableCell>
                            <TableCell>
                              {row.address._id && row.estimated_arrival_time
                                ? new Date(
                                  row.estimated_arrival_time,
                                ).toLocaleString()
                                : ''}
                              {!row.address._id && (
                                <DateTimePicker
                                  id={'datepicker_eat_' + type + index}
                                  value={DateTime.fromISO(
                                    row.estimated_arrival_time,
                                  )}
                                  onChange={(newValue) => {
                                    const loadCopy = { ...load };
                                    load.locations[
                                      index
                                    ].estimated_arrival_time = newValue;
                                    setLoad(loadCopy);
                                  }}
                                  slotProps={{ textField: { size: 'small' } }}
                                  fullWidth
                                  sx={locationFieldStyle}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <b>Departure Time</b>:
                            </TableCell>
                            <TableCell>
                              {row.address._id && row.departure_time
                                ? new Date(row.departure_time).toLocaleString()
                                : ''}
                              {!row.address._id && (
                                <DateTimePicker
                                  id={'datepicker_dt_' + type + index}
                                  value={DateTime.fromISO(row.departure_time)}
                                  onChange={(newValue) => {
                                    const loadCopy = { ...load };
                                    load.locations[index].departure_time =
                                      newValue;
                                    setLoad(loadCopy);
                                  }}
                                  slotProps={{ textField: { size: 'small' } }}
                                  fullWidth
                                  sx={locationFieldStyle}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        </Table>
                        <Box sx={{ marginTop: 0.5, float: 'right' }}>
                          {load.locations[index].address._id && (
                            <Button
                              size="small"
                              variant="outlined"
                              sx={{ marginRight: 0.5 }}
                              onClick={() => {
                                load.locations[index].address._id = undefined;
                                // Somtimes these may not exist if a load is created by a 3rd part
                                if (!load.locations[index].contact)
                                  load.locations[index].contact = {};
                                if (!load.locations[index].address)
                                  load.locations[index].address = {};
                                setLoad({ ...load });
                              }}
                            >
                              EDIT
                            </Button>
                          )}
                          <Button
                            size="small"
                            color="error"
                            variant="outlined"
                            onClick={() => {
                              load.locations.splice(index, 1);
                              setLoad({ ...load });
                            }}
                          >
                            DELETE
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </Fragment>
          ))}
        {/* <Table sx={{ minWidth: 650 }} aria-label="shipping table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: 1 }}>Address</TableCell>
              <TableCell sx={{ padding: 1 }}>Contact</TableCell>
              <TableCell sx={{ padding: 1 }}>Arrival Time</TableCell>
              <TableCell sx={{ padding: 1 }}>ETA</TableCell>
              <TableCell sx={{ padding: 1 }}>Departure Time</TableCell>
              <TableCell sx={{ padding: 1 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {load.locations &&
              load.locations.map((row, index) => (
                <Fragment key={'location_' + type + index}>
                  {row.type === type && (
                    <>
                      {!row.address._id && (
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            scope="row"
                            sx={{
                              verticalAlign: 'top',
                              width: '20%',
                              padding: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                color: '#888',
                                fontSize: '.8rem',
                              }}
                            >
                              {type} Address
                            </Typography>
                            <TextField
                              size="small"
                              label={'Line 1'}
                              value={row.address.line1}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.line1 =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'Line 2'}
                              value={row.address.line2}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.line2 =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'City'}
                              value={row.address.city}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.city =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'State'}
                              value={row.address.state}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.state =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'Zip'}
                              value={row.address.zip}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.zip =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'Country'}
                              value={row.address.country}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].address.country =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: 'top',
                              width: '20%',
                              padding: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                color: '#888',
                                fontSize: '.8rem',
                              }}
                            >
                              {type} Contact
                            </Typography>
                            <TextField
                              size="small"
                              label={'Name'}
                              value={row.contact.name}
                              fullWidth
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].contact.name =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'Email'}
                              value={row.contact.email}
                              fullWidth
                              type="email"
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].contact.email =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                            <TextField
                              size="small"
                              label={'Phone'}
                              value={row.contact.phone}
                              fullWidth
                              type="tel"
                              sx={locationFieldStyle}
                              onChange={(e) => {
                                load.locations[index].contact.phone =
                                  e.target.value;
                                setLoad({ ...load });
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: 'top',
                              width: '20%',
                              padding: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                color: '#888',
                                fontSize: '.8rem',
                              }}
                            >
                              {type} Arrival Time
                            </Typography>
                            <DateTimePicker
                              id={'datepicker_at_' + type + index}
                              value={DateTime.fromISO(row.arrival_time)}
                              onChange={(newValue) => {
                                const loadCopy = { ...load };
                                console.log(
                                  index,
                                  load.locations.filter(
                                    (loc) => loc.type === type,
                                  ),
                                );
                                load.locations[index].arrival_time = newValue;
                                setLoad(loadCopy);
                              }}
                              slotProps={{ textField: { size: 'small' } }}
                              fullWidth
                              sx={locationFieldStyle}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: 'top',
                              width: '20%',
                              padding: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                color: '#888',
                                fontSize: '.8rem',
                              }}
                            >
                              {type} ETA
                            </Typography>
                            <DateTimePicker
                              id={'datepicker_eat_' + type + index}
                              value={DateTime.fromISO(
                                row.estimated_arrival_time,
                              )}
                              onChange={(newValue) => {
                                const loadCopy = { ...load };
                                load.locations[index].estimated_arrival_time =
                                  newValue;
                                setLoad(loadCopy);
                              }}
                              slotProps={{ textField: { size: 'small' } }}
                              fullWidth
                              sx={locationFieldStyle}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: 'top',
                              width: '20%',
                              padding: 1,
                              position: 'relative',
                            }}
                            colSpan={2}
                          >
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                color: '#888',
                                fontSize: '.8rem',
                              }}
                            >
                              {type} Departure Time
                            </Typography>
                            <DateTimePicker
                              id={'datepicker_dt_' + type + index}
                              value={DateTime.fromISO(row.departure_time)}
                              onChange={(newValue) => {
                                const loadCopy = { ...load };
                                load.locations[index].departure_time = newValue;
                                setLoad(loadCopy);
                              }}
                              slotProps={{ textField: { size: 'small' } }}
                              fullWidth
                              sx={locationFieldStyle}
                            />

                            <Box
                              sx={{
                                float: 'right',
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                marginRight: 2,
                                marginBottom: 2,
                              }}
                            >
                              <Button
                                size="small"
                                color="error"
                                variant="outlined"
                                onClick={() => {
                                  load.locations.splice(index, 1);
                                  setLoad({ ...load });
                                }}
                              >
                                DELETE
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      {row.address._id && (
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            scope="row"
                            sx={{
                              verticalAlign: 'top',
                              padding: 1,
                            }}
                          >
                            {row.address.line1}
                            <br />
                            {row.address.line2 && (
                              <>
                                {row.address.line2}
                                <br />
                              </>
                            )}
                            {row.address.city}, {row.address.state}{' '}
                            {row.address.zip}
                            <br />
                            {row.address.country}
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: 'top',
                              padding: 1,
                            }}
                          >
                            {row.contact &&
                              (row.contact.name ||
                                row.contact.email ||
                                row.contact.phone) && (
                                <>
                                  {row.contact.name}
                                  <br />
                                  Email: {row.contact.email}
                                  <br />
                                  Phone: {row.contact.phone}
                                </>
                              )}
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: 'top',
                              padding: 1,
                            }}
                          >
                            {row.arrival_time
                              ? new Date(row.arrival_time).toLocaleString()
                              : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: 'top',
                              padding: 1,
                            }}
                          >
                            {row.estimated_arrival_time
                              ? new Date(
                                  row.estimated_arrival_time,
                                ).toLocaleString()
                              : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: 'top',
                              padding: 1,
                            }}
                          >
                            {row.departure_time
                              ? new Date(row.departure_time).toLocaleString()
                              : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              verticalAlign: 'top',
                              padding: 1,
                            }}
                          >
                            <Box>
                              <Button
                                size="small"
                                variant="outlined"
                                sx={{ marginBottom: 0.5 }}
                                onClick={() => {
                                  load.locations[index].address._id = undefined;
                                  // Somtimes these may not exist if a load is created by a 3rd part
                                  if (!load.locations[index].contact)
                                    load.locations[index].contact = {};
                                  if (!load.locations[index].address)
                                    load.locations[index].address = {};
                                  setLoad({ ...load });
                                }}
                              >
                                EDIT
                              </Button>
                              <br />
                              <Button
                                size="small"
                                color="error"
                                variant="outlined"
                                onClick={() => {
                                  load.locations.splice(index, 1);
                                  setLoad({ ...load });
                                }}
                              >
                                DELETE
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </Fragment>
              ))}
          </TableBody>
        </Table> */}
      </TableContainer>
    );
  };

  const renderLoadDetails = () => {
    return (
      <Grid
        container
        sx={{
          padding: 2,
          overflowY: 'scroll',
          maxHeight: 'calc(100vh - 100px)',
          borderBottom: '1px solid #eee',
        }}
      >
        {!user.isCustomer && (
          <Grid item xs={12} md={2} pr={2}>
            <TextField
              value={load.qe_id}
              onChange={updateTitle}
              label="QE ID"
              variant="standard"
              disabled={isReadOnly()}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-input': { padding: 0 },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'unset ',
                },
                '& .MuiOutlinedInput-root': {
                  fontSize: '1.5rem',
                  fontWeight: '700',
                },
                marginBottom: 1,
              }}
            />
          </Grid>
        )}
        {!user.isCustomer && (
          <Grid item xs={12} md={2} pr={2}>
            <TextField
              value={load.pro_number}
              onChange={updateProNumber}
              label="PRO"
              variant="standard"
              fullWidth
              disabled={isReadOnly()}
            />
          </Grid>
        )}
        <Grid item xs={12} md={user.isCustomer ? 6 : 2} pr={2}>
          <TextField
            value={load.po_number}
            label="PO Number"
            variant="standard"
            fullWidth
            disabled={isReadOnly()}
            onChange={(e) => {
              load.po_number = e.target.value;
              setLoad({ ...load });
            }}
          />
        </Grid>
        {!user.isCustomer && (
          <Grid item xs={12} md={2} pr={2}>
            <TextField
              value={load.reference_id}
              onChange={updateReferenceId}
              label="Reference ID"
              variant="standard"
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} md={user.isCustomer ? 6 : 2} pr={2}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="price">Cost/Price</InputLabel>
            <Input
              value={load.price}
              onChange={updatePrice}
              id="price"
              label="Cost/Price"
              variant="standard"
              fullWidth
              disabled={user.isCustomer}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} pr={2}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Mode</InputLabel>
            <Select
              fullWidth
              size={'small'}
              value={load.mode ? load.mode : ''}
              onChange={(event) => {
                setLoad({ ...load, ...{ mode: event.target.value } });
              }}
            >
              {Object.values(LoadMode).map((mode) => {
                return (
                  <MenuItem key={mode} value={mode}>
                    {mode}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} pr={2}>
          <TextField
            value={load.order_number}
            label="Order Number"
            variant="standard"
            fullWidth
            onChange={(event) => {
              setLoad({ ...load, ...{ order_number: event.target.value } });
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} pr={2}>
          <TextField
            value={load.commodity_type}
            label="Commodity Type"
            variant="standard"
            fullWidth
            onChange={(event) => {
              setLoad({ ...load, ...{ commodity_type: event.target.value } });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="standard" fullWidth sx={{ marginBottom: 1 }}>
            <InputLabel>Status</InputLabel>
            <Select
              value={load.section}
              onChange={updateStatus}
              disabled={isReadOnly()}
            >
              {props.sections.map((section) => {
                return (
                  <MenuItem key={section._id} value={section._id}>
                    {section.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12}>
          <FormControl variant="standard" fullWidth sx={{ marginBottom: 1 }}>
            <InputLabel>Agent</InputLabel>
            <Select
              value={load.created_by}
              onChange={(event) => {
                setLoad({ ...load, ...{ created_by: event.target.value } });
              }}
              disabled={isReadOnly()}
            >
              {siteContext.users.map((user) => {
                return (
                  <MenuItem key={user._id} value={user._id}>
                    {user.firstname} {user.lastname}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid> */}
        {!user.isCustomer &&
          load._id &&
          companies &&
          companies.length > 0 &&
          companyOrder.map((type, index) => {
            return (
              <Grid item xs={12} key={'company_' + index}>
                <FormControl
                  variant="standard"
                  size="small"
                  fullWidth
                  sx={{ marginBottom: 1 }}
                >
                  {newCompanyTypes[type] && (
                    <TextField
                      type="text"
                      autoFocus
                      variant="standard"
                      label={'Enter New ' + typeUpper(type)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              const newCompanyTypesCopy = {
                                ...newCompanyTypes,
                              };
                              newCompanyTypesCopy[type] = false;
                              setNewCompanyTypes(newCompanyTypesCopy);
                            }}
                          >
                            <CloseOutlined />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => {
                        updateCompany(event.target.value, type);
                      }}
                    />
                  )}
                  {!newCompanyTypes[type] && (
                    <Autocomplete
                      freeSolo
                      id={type}
                      options={[
                        {
                          name: 'Add New ' + typeUpper(type),
                          new: true,
                        },
                        ...companies.filter(
                          (company) => company.types.indexOf(type) >= 0,
                        ),
                      ]}
                      defaultValue={companies.find(
                        (company) =>
                          company._id === load[type] ||
                          (companies.length === 1 && type === 'customer'),
                      )}
                      disabled={
                        (companies.length === 1 && type === 'customer') ||
                        isReadOnly()
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant="standard"
                            label={typeUpper(type)}
                          />
                        );
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            key={type + option.name}
                            sx={{
                              marginX: 1,
                              fontStyle: option.new ? 'italic' : 'inherit',
                              borderBottom: option.new
                                ? '1px solid #DDD'
                                : 'none',
                              cursor: 'pointer',
                            }}
                          >
                            {option.name}
                          </li>
                        );
                      }}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          if (newValue._id) {
                            updateCompany(newValue._id, type);
                          } else if (newValue.new) {
                            const newCompanyTypesCopy = {
                              ...newCompanyTypes,
                            };
                            newCompanyTypesCopy[type] = true;
                            setNewCompanyTypes(newCompanyTypesCopy);
                          }
                        } else updateCompany(null, type);
                      }}
                    />
                  )}
                </FormControl>
              </Grid>
            );
          })}
        <Grid item xs={6} pr={2}>
          <TextField
            value={load.tracking_url}
            onChange={updateTrackingUrl}
            label="Tracking URL"
            variant="standard"
            fullWidth
            disabled={isReadOnly()}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={load.tracking_number}
            label="Tracking Number"
            variant="standard"
            disabled
            fullWidth
          />
        </Grid>
        {!user.isCustomer && (
          <Grid item xs={6} pr={2}>
            <TextField
              type="number"
              value={alert.number}
              onChange={(event) => {
                const alertCopy = { ...alert };
                alertCopy.number = event.target.value;
                updateAlert(alertCopy);
              }}
              label="Alert Time"
              variant="standard"
              fullWidth
            />
          </Grid>
        )}
        {!user.isCustomer && (
          <Grid item xs={6}>
            <FormControl variant="standard" fullWidth>
              <InputLabel>Alert Unit</InputLabel>
              <Select
                value={alert.unit}
                label="Alert Unit"
                variant="standard"
                onChange={(event) => {
                  const alertCopy = { ...alert };
                  alertCopy.unit = event.target.value;
                  updateAlert(alertCopy);
                }}
              >
                <MenuItem value={''}>None</MenuItem>
                <MenuItem value={'minutes'}>Minutes</MenuItem>
                <MenuItem value={'hours'}>Hours</MenuItem>
                <MenuItem value={'days'}>Days</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} mt={2}>
          <TextField
            value={load.notes}
            label="Notes"
            fullWidth
            multiline
            variant="filled"
            onChange={updateNotes}
            disabled={isReadOnly()}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      open={load._id !== undefined}
      onClose={onClose}
      scroll={scroll}
      fullScreen={true}
      sx={{ margin: 3 }}
      PaperProps={{ sx: { borderRadius: 1 } }}
    >
      <DialogContent dividers={scroll === 'paper'}>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={closeSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={closeSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
        {load._id === undefined || companies.length === 0 || loading ? (
          <Box sx={{ marginY: 2 }}>
            <Loading />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                float: 'right',
                width: 'auto',
              }}
            >
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={currentTab}
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={(event, newVal) => setCurrentTab(newVal)}
              >
                <Tab label="Details" id="details" />
                <Tab label="Items" id="items" />
                <Tab label="Locations" id="Locations" />
                <Tab label="Claims" id="Claims" />
                <Tab label="History" id="history" />
                <Tab label="Files" id="files" disabled={!Boolean(load?.reference_id)}/>
              </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
              {renderLoadDetails()}
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              {renderItems()}
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <Box my={3}>
                {renderLocations('pickup', 'Pick Up Locations')}
                <Box mt={2} />
                {renderLocations('dropoff', 'Drop Off Locations')}
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <List
                dense={true}
                sx={{
                  maxHeight: 'calc(100vh - 80px)',
                  overflowY: 'scroll',
                }}
              >
                <>
                  <ListItem>
                    <Card
                      variant="elevation"
                      sx={{
                        fontSize: '.75rem',
                        width: '100%',
                        padding: 0.5,
                      }}
                    >
                      <Box sx={{ float: 'right', color: '#777' }}>
                        Submitted Date:
                      </Box>
                      <Box sx={{ color: '#777' }}>Claim Number:</Box>
                      <Box sx={{ whiteSpace: 'pre-line' }}>{ }</Box>
                    </Card>
                  </ListItem>
                </>
              </List>
            </TabPanel>
            <TabPanel value={currentTab} index={4}>
              <List
                dense={true}
                sx={{
                  maxHeight: 'calc(100vh - 80px)',
                  overflowY: 'scroll',
                }}
              >
                {load.histories && load.histories.length > 0 ? (
                  <>
                    {[...load.histories].reverse().map((history, index) => {
                      return (
                        <ListItem key={'history_' + index}>
                          <Card
                            variant="elevation"
                            sx={{
                              fontSize: '.75rem',
                              width: '100%',
                              padding: 0.5,
                            }}
                          >
                            <Box sx={{ float: 'right', color: '#777' }}>
                              {new Date(history.timestamp).toLocaleString()}
                            </Box>
                            <Box sx={{ color: '#777' }}>
                              User: {history.user}
                            </Box>
                            {history.description.map((desc, index) => {
                              return (
                                <Box
                                  sx={{ whiteSpace: 'pre-line' }}
                                  key={'history_desc_' + index}
                                >
                                  {desc}
                                </Box>
                              );
                            })}
                          </Card>
                        </ListItem>
                      );
                    })}
                  </>
                ) : (
                  <Alert variant="standard" severity="info">
                    No History
                  </Alert>
                )}
              </List>
            </TabPanel>
            <TabPanel value={currentTab} index={5}>              
              <FormPODAndBOD
                load={load}
                files={files}
                handleSetFiles={(value) => setFiles(value)}
              />
            </TabPanel>
          </>
        )}
      </DialogContent>
      <DialogActions
        disableSpacing={true}
        sx={{ justifyContent: 'space-between', padding: '20px 24px' }}
      >
        <Box>
          {section.allow_archive === true && !isReadOnly() && (
            <Button
              size="small"
              variant="text"
              color="warning"
              onClick={archive}
              sx={{
                float: 'left',
                marginLeft: 1,
              }}
            >
              Archive
            </Button>
          )}
        </Box>
        <Box>
          {/* Disabled until it's Trinity API is ready and working
          {!load.qe_id && load.reference_id && (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  value={load.export_to_qe}
                  onChange={(event) => {
                    setLoad({
                      ...load,
                      ...{ export_to_qe: event.target.checked },
                    });
                  }}
                />
              }
              label="Export to QE"
              sx={{ marginRight: 5 }}
            />
          )} */}
          {!isReadOnly() && (
            <Button
              variant="contained"
              size="small"
              disableElevation
              onClick={save}
              disabled={disableSaveCloseBtn}
            >
              Save &amp; Close
            </Button>
          )}
        </Box>
        {isReadOnly() && (
          <Button
            variant="contained"
            size="small"
            disableElevation
            onClick={() => onClose(false)}
            sx={{ float: 'right' }}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default LoadModal;
